import React from "react";
import mapImage from "../assets/Sahasra Technical Services image 5.jpg";
import viImage from "../assets/WhatsApp Image 2024-04-05 at 00.24.18_77c45cc1.jpg"
import tataImage1 from "../assets/WhatsApp Image 2024-04-05 at 00.45.34_43b07ed5.jpg"
import tataImage2 from "../assets/WhatsApp Image 2024-04-05 at 00.58.23_1ed59970.jpg"

const Clients = () => {
  return (
    <div className="container mt-3 clients-section" id="clients">
      <div className="h1 text-center text-dark">Our Clients</div>
      <div className="row mt-5">
        <div
          id="carouselExampleCaptions"
          class="carousel slide "
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="https://cloudfront-us-east-2.images.arcpublishing.com/reuters/7JBRI34WRRP3HABELJK7Y66QEM.jpg"
                class="d-block w-100"
                alt="NOKIA"
                height="550px"
              />
              <div class="carousel-caption  d-block">
                <h5>ONGOING PROJECTS</h5>
                <p>
                  RFI Survey & G-NodeB installations of 3.5G and 0.7G band's for
                  Nokia In JIO 5G Project.
                </p>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src="https://etimg.etb2bimg.com/photo/101413516.cms"
                class="d-block w-100"
                alt="vi"
                height="550px"
              />
              <div class="carousel-caption  d-block">
                <h5>ONGOING PROJECTS</h5>
                <p>
                  RFI Survey & Microwave & 2G BTS & Node-B & E-NodeB
                  Installations of TDD and FDD technology's for ZTE in Vodafone
                  Idea Project.
                </p>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src={viImage}
                class="d-block w-100"
                alt="vi 2"
                height="550px"
              />
              <div class="carousel-caption  d-block">
                <h5>ONGOING PROJECTS</h5>
                <p>
                  RFI Survey & Microwave & 2G BTS & Node-B & E-NodeB
                  Installations of TDD and FDD technologys for Vodafone Idea.
                </p>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src="https://tradebrains.in/wp-content/uploads/2022/07/Tata-TELE-and-COm-Cover-Image.jpg"
                class="d-block w-100"
                alt="vi 2"
                height="550px"
              />
              <div class="carousel-caption  d-block">
                <h5>ONGOING PROJECTS</h5>
                <p>
                  Transmission equipment installations of MUX & DSLAM & DWDM&
                  SWITCH& SERVER Installations for TTSL
                </p>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src={tataImage1}
                class="d-block w-100"
                alt="vi 2"
                height="550px"
              />
              <div class="carousel-caption  d-block">
                <h5>ONGOING PROJECTS</h5>
                <p>Battery bank and Powerplant installations for TTSL</p>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src={tataImage2}
                class="d-block w-100"
                alt="vi 2"
                height="550px"
              />
              <div class="carousel-caption  d-block">
                <h5>ONGOING PROJECTS</h5>
                <p>DLC and POP shifting for TTSL</p>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="h1 text-center text-dark mt-5">Our Service Circle </div>
      <div className="row mt-5 justify-content-center align-items-center">
        <div className="col-12 col-md">
          <img src={mapImage} alt="" className="img-fluid rounded" />
        </div>
        <div className="col-12 col-md">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>CLIENTS</th>
                <th>Circle</th>
                <th>PRESENCE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>TTSL</td>
                <td>AP</td>
                <td>Ti-Vendor + Logistics</td>
              </tr>
              <tr>
                <td>VODAFONE IDEA</td>
                <td>AP</td>
                <td>Ti-Vendor + Logistics</td>
              </tr>
              <tr>
                <td>Airtel</td>
                <td>AP</td>
                <td>Ti-Vendor</td>
              </tr>
              <tr>
                <td>JIO</td>
                <td>AP</td>
                <td>Ti-Vendor</td>
              </tr>
              <tr>
                <td>Ceragon</td>
                <td>AP</td>
                <td>Ti-Vendor</td>
              </tr>
              <tr>
                <td>ZTE</td>
                <td>AP & KARNATAKA</td>
                <td>Ti-vendor + Logistics</td>
              </tr>
              <tr>
                <td>HUAWEI</td>
                <td>AP & KARNATAKA</td>
                <td>Ti-vendor</td>
              </tr>
              <tr>
                <td>Nokia</td>
                <td>AP & TS & KARNATAKA</td>
                <td>Ti-vendor</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="h1 text-center text-dark mt-5">Executed Projects</div>
      <div className="row mt-5">
        <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
          <div className="position-relative">
            <img
              src="https://cloudfront-us-east-2.images.arcpublishing.com/reuters/7JBRI34WRRP3HABELJK7Y66QEM.jpg"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
            <div className="overlay-text rounded">
              800+ G-NodeB installations for Nokia in JIO 5G project
            </div>
          </div>

          <div className="position-relative">
            <img
              src="https://etimg.etb2bimg.com/photo/101413516.cms"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Wintry Mountain Landscape"
            />
            <div className="overlay-text rounded">
              2000 + TDD & FDD & MW sites integrated for ZTE in Vodafone Idea
              Project.
            </div>
          </div>
        </div>

        <div className="col-lg-4 mb-4 mb-lg-0">
          <div className="position-relative">
            <img
              src="https://wi.wallpapertip.com/wsimgs/25-259863_huawei-logo-and-hq-wallpapers-huawei-make-it.jpg"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Mountains in the Clouds"
            />
            <div className="overlay-text rounded">
              1000+ sites TDD & FDD installations for Huawei in Bharti 4G
              project.{" "}
            </div>
          </div>

          <div className="position-relative">
            <img
              src="https://archive-images.prod.global.a201836.reutersmedia.net/2019/12/15/LYNXMPEFBE09Q.JPG"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
            <div className="overlay-text rounded">
              700+ Sites Installations for Huawei in Telenor 4G Project.
            </div>
          </div>
        </div>

        <div className="col-lg-4 mb-4 mb-lg-0">
          <div className="position-relative">
            <img
              src="https://images.gizbot.com/img/2021/01/bsnl3-1609860239.jpg"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Waves at Sea"
            />
            <div className="overlay-text rounded">
              1500+ BTS & NodeB & Microwave Installations for ZTE in BSNL
              project.
            </div>
          </div>

          <div className="position-relative">
            <img
              src="https://cdn.ndtv.com/tech/images/gadgets/tata_docomo_logo_official.jpg"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Yosemite National Park"
            />
            <div className="overlay-text rounded">
              1000+ 2G BTS Installations for ZTE in Tata Docomo project{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
