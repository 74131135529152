import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faPhone,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div className="light">
      <div className="container mt-5" id="contact">
        <div className="h1 text-center text-dark py-4">Contact Us</div>

        <div className="row">
          <div className="col-12 col-lg  d-flex flex-column  align-items-center border-right p-4">
            <div className="contact-logos d-flex  justify-content-center align-items-center">
              <i className="">
                <FontAwesomeIcon icon={faLocationDot} />
              </i>
            </div>

            <h4 className="mt-2 text-black">Office Address</h4>
            <p className="text-black contact-small-text text-center">
              SAHASRA TECHNICAL SERVICES PVT LTD Plot No 151, Road No 2, East
              Park Lane,Airforce Officers Colony, Vayupuri, Nexa Showroom Back
              Side Road, Hyderabad,Telangana,500040 040-27144199
            </p>
          </div>
          <div className="col-12 col-lg  d-flex flex-column align-items-center border-right p-4">
            <div className="contact-logos d-flex  justify-content-center align-items-center">
              <i className="">
                <FontAwesomeIcon icon={faEnvelope} />
              </i>
            </div>
            <h4 className="mt-2 text-black">General Enquiries</h4>

            <p className=" text-black contact-small-text text-center mt-4">
              info@sahasratechnicalservices.com <br /> sts.sahasra@gmail.com
            </p>
          </div>
          <div className="col-12 col-lg  d-flex flex-column  align-items-center border-right p-4">
            <div className="contact-logos d-flex  justify-content-center align-items-center">
              <i className="">
                <FontAwesomeIcon icon={faPhone} />
              </i>
            </div>
            <h4 className="mt-2 text-black">Call Us</h4>

            <p className=" text-black contact-small-text text-center mt-4">
              040-79623639 <br />
              +91 9939931116
            </p>
          </div>
          <div className="col-12 col-lg  d-flex flex-column  align-items-center p-4">
            <div className="contact-logos d-flex  justify-content-center align-items-center">
              <i className="">
                <FontAwesomeIcon icon={faClock} />
              </i>
            </div>
            <h4 className="mt-2 text-black">Working Hours</h4>

            <p className="text-black contact-small-text text-center mt-4">
              Mon - Sat : 9:00 AM - 06:00 PM
            </p>
          </div>
        </div>

        <div className="row py-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.48365831075!2d78.5453869!3d17.484410699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b3733d3ca29%3A0x7d0cd49ab5d317c3!2sSAHASRA%20TECHNICAL%20SERVICES%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1711471085567!5m2!1sen!2sin"
            width="800"
            height="600"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="bor  rounded shadow"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
