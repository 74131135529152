import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Footer from "./components/footer";
import OurServices from "./components/OurServices";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Home from "./Home";

function App() {
  return (
    <div className="App">
      <Navbar />

      <Home/>
      <Contact />
    </div>
  );
}

export default App;
