import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import OurServices from './components/OurServices'
import Clients from './components/Clients'
import Contact from './components/Contact'

const Home = () => {
  return (
    <div >
      <Hero/>
      <OurServices/>
      <Clients/>
      
    </div>
  )
}

export default Home
