import React from "react";
import HeroVideo from "../assets/testvideo.mp4";
import HeroVideo1 from "../assets/testvideo1.mp4";
import tataLogo from "../assets/Tata_Teleservices_new_logo.png";
import jiologo from "../assets/Jio-Logo-768x483.png";
import airtelLogo from "../assets/Airtel-logo-768x432.png";
import viLogo from "../assets/VI-Vodafone-Idea-900x0.png";
import cergonLogo from "../assets/Ceragon_logo.svg.png";
import Ztelogo from "../assets/ZTE-logo.svg.png";
import HuwaiLogo from "../assets/1200px-Huawei_Standard_logo.svg.png";
import NokiaLogo from "../assets/Nokia-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="overlay"></div>
      <video src={HeroVideo1} autoPlay loop muted />
      <div className="hero-text text-center">
        <p data-aos="fade-up" data-aos-easing="ease" data-aos-delay="300">
          <span className="spantext1"> Leading Telecom Operators</span>
          <br />
          <span className="spantext1"> and Vendors</span>
          <br />
          in INDIA
        </p>
        <div className="div">
          <a className="btn btn-custom shadow " href="#contact">
            Contact Us
            <i>
              <FontAwesomeIcon icon={faArrowRight} />
            </i>
          </a>
        </div>
      </div>

      <div className="hero_clients  text-white ">
        <div className="div">
          <p className="text-center">Our Successful Clients</p>
          <img src={tataLogo} alt="" width="100px" />
          <img src={jiologo} alt="" width="50px" />
          <img src={cergonLogo} alt="" width="80px" />
          <img src={airtelLogo} className="pleft" alt="" width="50px" />
          <img src={viLogo} alt="" width="50px" />
          <img src={Ztelogo} alt="" width="30px" />
          <img src={NokiaLogo} className="pleft" alt="" width="70px" />
          <img src={HuwaiLogo} className="pleft" alt="" width="40px" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
