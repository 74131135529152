import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/logobg2.png";

const Navbar = () => {
  const [HamShow, setHamShow] = useState(false);
  const [flag, setFlag] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      console.log(window.scrollY);
      console.log("Window Inner Height:", window.innerHeight);
      if (window.scrollY > window.innerHeight) {
        setFlag(false);
        console.log("outside  hero section ", flag);
      } else {
        setFlag(true);
        console.log("inside hero section ", flag);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [flag]);

  return (
    <div>
      <section className="">
        <nav
          className={`navbar navbar-expand-lg fixed-top  ${
            flag ? "navbar-dark" : "custom-navbar "
          }`}
        >
          <div className="container">
            <Link to="/">
              <i>
                <img
                  src={Logo}
                  alt="company-logo"
                  height={`${flag ? "70px" : "40px"}`}
                  className="navbar-brand"
                />
              </i>
            </Link>
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setHamShow(!HamShow);
              }}
            >
              <i className="hamburger">
                <FontAwesomeIcon icon={faBars} />
              </i>
            </button>
            <div
              className={`collapse navbar-collapse ${HamShow ? "show" : ""}   `}
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    exact
                    className="nav-link pe-4"
                    aria-current="page"
                    onClick={() => {
                      setHamShow(!HamShow);
                    }}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                    href="#ourservices"
                    className="nav-link pe-4 "
                    aria-current="page"
                    activeClassName="active"
                    onClick={() => {
                      setHamShow(!HamShow);
                    }}
                  >
                    Services
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="#clients"
                    className="nav-link pe-4"
                    aria-current="page"
                    activeClassName="active"
                    onClick={() => {
                      setHamShow(!HamShow);
                    }}
                  >
                    Clients
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#contact"
                    className="nav-link pe-4"
                    aria-current="page"
                    activeClassName="active"
                    onClick={() => {
                      setHamShow(!HamShow);
                    }}
                  >
                    ContactUs
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
};

export default Navbar;
