import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ProjManagment from "../assets/WhatsApp Image 2024-03-25 at 00.21.37_941e94e3.jpg";
import siteServices from "../assets/Sahasra Technical Services image 2.jpg";
import btsservice from "../assets/Sahasra Technical Services image 3.jpg";
import lanWan from "../assets/WhatsApp Image 2024-03-18 at 00.06.41_f4bdab00.jpg";
import network from "../assets/WhatsApp Image 2024-03-18 at 00.06.42_d359d197.jpg";
import infrastructure from "../assets/Sahasra Technical Services image 1.jpg";
import resource from "../assets/Sahasra Technical Services image 4.jpg";

const OurServices = () => {
  return (
    <div>
      {/* <section class="dark">
        <div class="container py-4">
          <h1 class="h1 text-center" id="pageHeaderTitle">
            My Cards Dark
          </h1>

          <article class="postcard dark blue">
            <a class="postcard__img_link" href="#">
              <img
                class="postcard__img"
                src="https://picsum.photos/1000/1000"
                alt="Image Title"
              />
            </a>
            <div class="postcard__text">
              <h1 class="postcard__title blue">
                <a href="#">Podcast Title</a>
              </h1>
              <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div>
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi, fugiat asperiores inventore beatae accusamus odit
                minima enim, commodi quia, doloribus eius! Ducimus nemo
                accusantium maiores velit corrupti tempora reiciendis molestiae
                repellat vero. Eveniet ipsam adipisci illo iusto quibusdam, sunt
                neque nulla unde ipsum dolores nobis enim quidem excepturi,
                illum quos!
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Podcast
                </li>
                <li class="tag__item">
                  <i class="fas fa-clock mr-2"></i>55 mins.
                </li>
                <li class="tag__item play blue">
                  <a href="#">
                    <i class="fas fa-play mr-2"></i>Play Episode
                  </a>
                </li>
              </ul>
            </div>
          </article>
          <article class="postcard dark red">
            <a class="postcard__img_link" href="#">
              <img
                class="postcard__img"
                src="https://picsum.photos/501/500"
                alt="Image Title"
              />
            </a>
            <div class="postcard__text">
              <h1 class="postcard__title red">
                <a href="#">Podcast Title</a>
              </h1>
              <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div>
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi, fugiat asperiores inventore beatae accusamus odit
                minima enim, commodi quia, doloribus eius! Ducimus nemo
                accusantium maiores velit corrupti tempora reiciendis molestiae
                repellat vero. Eveniet ipsam adipisci illo iusto quibusdam, sunt
                neque nulla unde ipsum dolores nobis enim quidem excepturi,
                illum quos!
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Podcast
                </li>
                <li class="tag__item">
                  <i class="fas fa-clock mr-2"></i>55 mins.
                </li>
                <li class="tag__item play red">
                  <a href="#">
                    <i class="fas fa-play mr-2"></i>Play Episode
                  </a>
                </li>
              </ul>
            </div>
          </article>
          <article class="postcard dark green">
            <a class="postcard__img_link" href="#">
              <img
                class="postcard__img"
                src="https://picsum.photos/500/501"
                alt="Image Title"
              />
            </a>
            <div class="postcard__text">
              <h1 class="postcard__title green">
                <a href="#">Podcast Title</a>
              </h1>
              <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div>
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi, fugiat asperiores inventore beatae accusamus odit
                minima enim, commodi quia, doloribus eius! Ducimus nemo
                accusantium maiores velit corrupti tempora reiciendis molestiae
                repellat vero. Eveniet ipsam adipisci illo iusto quibusdam, sunt
                neque nulla unde ipsum dolores nobis enim quidem excepturi,
                illum quos!
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Podcast
                </li>
                <li class="tag__item">
                  <i class="fas fa-clock mr-2"></i>55 mins.
                </li>
                <li class="tag__item play green">
                  <a href="#">
                    <i class="fas fa-play mr-2"></i>Play Episode
                  </a>
                </li>
              </ul>
            </div>
          </article>
          <article class="postcard dark yellow">
            <a class="postcard__img_link" href="#">
              <img
                class="postcard__img"
                src="https://picsum.photos/501/501"
                alt="Image Title"
              />
            </a>
            <div class="postcard__text">
              <h1 class="postcard__title yellow">
                <a href="#">Podcast Title</a>
              </h1>
              <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div>
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi, fugiat asperiores inventore beatae accusamus odit
                minima enim, commodi quia, doloribus eius! Ducimus nemo
                accusantium maiores velit corrupti tempora reiciendis molestiae
                repellat vero. Eveniet ipsam adipisci illo iusto quibusdam, sunt
                neque nulla unde ipsum dolores nobis enim quidem excepturi,
                illum quos!
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Podcast
                </li>
                <li class="tag__item">
                  <i class="fas fa-clock mr-2"></i>55 mins.
                </li>
                <li class="tag__item play yellow">
                  <a href="#">
                    <i class="fas fa-play mr-2"></i>Play Episode
                  </a>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </section> */}

      <section class="light">
        <div class="container py-2">
          <div class="h1 text-center text-dark" id="ourservices">
            Services We Provided
          </div>
          <article class="postcard light blue">
            <a class="postcard__img_link" href="#">
              <img
                class="postcard__img"
                src={ProjManagment}
                alt="Image Title"
              />
            </a>
            <div class="postcard__text t-dark">
              <h1 class="postcard__title blue">
                <a href="#">Project Management</a>
              </h1>
              {/* <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div> */}
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                Comprehensive project management services to ensure smooth
                execution and timely delivery of telecom projects
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Project Management
                </li>
              </ul>
            </div>
          </article>
          <article class="postcard light yellow">
            <a class="postcard__img_link" href="#">
              <img
                class="postcard__img"
                src={infrastructure}
                alt="Image Title"
              />
            </a>
            <div class="postcard__text t-dark">
              <h1 class="postcard__title yellow">
                <a href="#">Infrastructure Services</a>
              </h1>
              {/* <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div> */}
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                <strong>DFCA Installation & Commissioning:</strong>
                <br />
                Deployment and commissioning of Distributed Fiber Cable Antenna
                (DFCA) systems for enhanced network connectivity. <br />
                <strong>
                  TMA (Tower-Mounted Amplifier) Installation and Integration:
                </strong>
                <br />
                Installation and integration of TMA equipment for improved
                signal strength and coverage.
                <br />
                <strong>In-Building Solutions:</strong>
                <br />
                Cable routing and equipment installation within buildings for
                seamless indoor coverage.
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Infrastructure
                </li>
              </ul>
            </div>
          </article>

          <article class="postcard light red">
            <a class="postcard__img_link" href="#">
              <img
                class="postcard__img img-fluid"
                src={siteServices}
                alt="Image Title"
              />
            </a>
            <div class="postcard__text t-dark">
              <h1 class="postcard__title red">
                <a href="#">Site Services</a>
              </h1>
              {/* <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div> */}
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                <strong>Site Survey, Leasing, and Acquisition: </strong> <br />
                Thorough site surveys, leasing negotiations, and site
                acquisition services to facilitate network expansion. <br />
                <strong>
                  {" "}
                  Equipment Installation, Integration, and Commissioning:{" "}
                </strong>
                <br />
                Expert installation and integration of various telecom equipment
                including MSC, BSC, BTS, and more.
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Site Survey
                </li>
                <li class="tag__item">
                  <i class="fas fa-clock mr-2"></i>Leasing
                </li>
                <li class="tag__item play red">
                  <a href="#">
                    <i class="fas fa-play mr-2"></i>Acquisition
                  </a>
                </li>
                <li class="tag__item play red">
                  <a href="#">
                    <i class="fas fa-play mr-2"></i>Integration
                  </a>
                </li>
              </ul>
            </div>
          </article>
          <article class="postcard light green">
            <a class="postcard__img_link" href="#">
              <img class="postcard__img" src={btsservice} alt="Image Title" />
            </a>
            <div class="postcard__text t-dark">
              <h1 class="postcard__title green">
                <a href="#">BTS Installation</a>
              </h1>
              {/* <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div> */}
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                Installation and commissioning services for a variety of BTS
                types including Flexi, wall-mounted, Ultra, Multi-radio, ZTE,
                Huawei, Lucent, etc
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>BTS Installation
                </li>
              </ul>
            </div>
          </article>

          <article class="postcard light blue">
            <a class="postcard__img_link" href="#">
              <img class="postcard__img" src={network} alt="Image Title" />
            </a>
            <div class="postcard__text t-dark">
              <h1 class="postcard__title blue">
                <a href="#">Network Services</a>
              </h1>
              {/* <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div> */}
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                <strong>Network Planning and Drive Tests:</strong> <br />
                Thorough network planning and drive test services to optimize
                coverage and performance.
                <br />
                <strong>Network Optimization:</strong>
                <br />
                Optimization services to fine-tune network parameters for
                enhanced efficiency and performance.
                <br />
                <strong>Network O&M (Operations & Maintenance):</strong>
                <br />
                Comprehensive network operations and maintenance services to
                ensure network reliability and uptime.
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Network Planning
                </li>
                <li class="tag__item">
                  <i class="fas fa-clock mr-2"></i>Network Optimization
                </li>
                <li class="tag__item play blue">
                  <a href="#">
                    <i class="fas fa-play mr-2"></i>Drive Tests
                  </a>
                </li>
                <li class="tag__item play blue">
                  <a href="#">
                    <i class="fas fa-play mr-2"></i>Operations & Maintenance
                  </a>
                </li>
              </ul>
            </div>
          </article>

          <article class="postcard light red">
            <a class="postcard__img_link" href="#">
              <img class="postcard__img" src={resource} alt="Image Title" />
            </a>
            <div class="postcard__text t-dark">
              <h1 class="postcard__title red">
                <a href="#">Resource Provisioning</a>
              </h1>
              {/* <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div> */}
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                Provision of skilled manpower and materials to support telecom
                infrastructure projects.
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>Resource Provisioning
                </li>
              </ul>
            </div>
          </article>

          <article class="postcard light green">
            <a class="postcard__img_link" href="#">
              <img class="postcard__img" src={lanWan} alt="Image Title" />
            </a>
            <div class="postcard__text t-dark">
              <h1 class="postcard__title green">
                <a href="#">LAN and WAN Solutions</a>
              </h1>
              {/* <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                  <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
              </div> */}
              <div class="postcard__bar"></div>
              <div class="postcard__preview-txt">
                End-to-end solutions for Local Area Network (LAN) and Wide Area
                Network (WAN) deployment and management.
              </div>
              <ul class="postcard__tagbox">
                <li class="tag__item">
                  <i class="fas fa-tag mr-2"></i>LAN
                </li>
                <li class="tag__item">
                  <i class="fas fa-clock mr-2"></i>WAN
                </li>
              </ul>
            </div>
          </article>
        </div>
      </section>
    </div>
  );
};

export default OurServices;
